<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title> Referal Schools </v-card-title>
          <v-card outlined>
            <v-card-title class="title mb-0 pb-0">
              <v-flex xs4>
                <v-text-field
                  id="staff_name_filter"
                  hint="Atleast 3 characters"
                  text
                  outlined
                  dense
                  color="primary"
                  class="pa-0 staff_name_filter"
                  label="Search by Name or Address"
                  v-model="search"
                  append-icon="search"
                  @input="searchSchoolReferal"
                />
              </v-flex>
              <transition
                name="animate-css-transition"
                enter-active-class="animated fadeInRight"
                leave-active-class="animated fadeOutRight"
              >
                <v-btn
                  id="deleted_user_clear_filter"
                  outlined
                  color="primary"
                  text
                  class="mt-n7 staff_clear_filter"
                  @click="(search = ''), getReferalSchools()"
                >
                  Clear
                </v-btn>
              </transition>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="referalSchools"
            :options.sync="pagination"
            :footer-props="footerProps"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ ++index }}</td>
                <td align="left" class="text-xs-left">
                  {{ item.name.capitalizeFirstLetter() }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.phone_number }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.address }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.contact_person.capitalizeFirstLetter() }}
                </td>
                <td align="left" class="text-xs-left">
                  {{ item.description }}
                </td>
                <td align="left" class="text-xs-left">
                  <v-chip
                    small
                    label
                    color="primary"
                    text-color="white"
                    v-if="item.enrolled == 0"
                  >
                    No
                  </v-chip>
                  &nbsp;
                  <v-chip
                    small
                    label
                    color="green"
                    text-color="white"
                    v-if="item.enrolled == 1"
                  >
                    Yes
                  </v-chip>
                </td>

                <td align="right" class="text-xs-right">
                  <edit-button
                    v-if="item.enrolled == 0"
                    color="warning"
                    icon="edit"
                    label="Enroll"
                    message="Are your sure you want to enroll this school"
                    editText="Enroll"
                    permission="school-read"
                    @agree="enrollSchool(item.id)"
                  >
                  </edit-button>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Contact Number",
        align: "left",
        value: "phone_number",
        sortable: false,
      },
      { text: "Address", align: "left", value: "address", sortable: false },
      {
        text: "Contact Person",
        align: "left",
        value: "contact_person",
        sortable: false,
      },
      {
        text: "Description",
        align: "left",
        value: "description",
        sortable: false,
      },
      { text: "Enrolled", align: "left", value: "enrolled", sortable: false },
      { text: "Action", align: "right", sortable: false },
    ],
    referalSchools: [],
    pagination: {},
    search: "",
  }),
  mounted() {
    // this.getReferalSchools();
  },
  methods: {
    searchSchoolReferal: function (v) {
      if (v.length > 2 || !v.length) this.getReferalSchools();
    },
    getReferalSchools() {
      let extraParams = "search=" + this.search.toString();
      let query = this.queryString(extraParams);
      this.$rest
        .get("/api/referal-schools" + query)
        .then(({ data }) => {
          this.referalSchools = data.data;
          this.pagination.totalItems = data.meta.total;
        })
        .catch((err) => {});
    },
    enrollSchool(id) {
      console.log(id, "enroll");
      this.$rest
        .put("/api/referal-schools/" + id)
        .then((res) => {
          this.$events.fire("notification", { message: res.data.message });
          this.getReferalSchools();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
  },
  watch: {
    pagination: function () {
      this.getReferalSchools();
    },
  },
};
</script>
